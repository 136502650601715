<template>
<div>
    <TreeChart v-if="isShow" :json="treeData" :class="{landscape: isVertical}" :isDetail="isDetail"/>
</div>
</template>

<script>
import TreeChart from '@/components/treeData'
import {
    Loading
} from "element-ui";

export default {
    name: 'tree',
    components: {
        TreeChart
    },
    data() {
        return {
			isShow:false,
            treeData: {
                ext_department_name: this.$root.userInfo.ext_company_name,
                id: 0,
                son: [{
                    ext_department_name: '股东1',
                    proportionShares: '50',
                    partnerType: 1,
                    id: 2,
                    partnerCode: 1,
					son:[{
						ext_department_name: '股东222',
	                    proportionShares: '520',
	                    partnerType: 1,
	                    id: 2,
	                    partnerCode: 2,
						son:[{
							ext_department_name: '股东222',
		                    proportionShares: '520',
		                    partnerType: 1,
		                    id: 2,
		                    partnerCode: 2,
						},{
							ext_department_name: '股东22222',
		                    proportionShares: '520',
		                    partnerType: 1,
		                    id: 2,
		                    partnerCode: 2,
						}]
					},{
						ext_department_name: '股东22222',
	                    proportionShares: '520',
	                    partnerType: 1,
	                    id: 2,
	                    partnerCode: 2,
						son:[{
							ext_department_name: '股东22222',
		                    proportionShares: '520',
		                    partnerType: 1,
		                    id: 2,
		                    partnerCode: 2,
						}]
					}]
                }]
            },
            isVertical: true, // 是否是竖方向,只给最外层的添加
            isDetail: true, // 是否是详情,不可编辑操作
            dialogVisible: false, // 添加股东弹框
            dialogVisible2: false, // 删除提示弹框

            lastId: 11, // 最后一级id
            currentTreeData: {}
        }
    },
	mounted(){
		this.getData()
	},
    methods: {
		getData() {
            this.http.post('/admin.user/indexWorkOutLook').then(re => {
                // this.treeData.son = re;
				this.isShow=true
				// console.log(re);
            })
        },
    }
}
</script>
